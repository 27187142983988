import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import logo from './assents/images/logo.svg';

const Logo = styled('img')(({ theme }) => ({
  padding: theme.spacing(1),
  [theme.breakpoints.down('md')]: {
    width: '20rem',
  },
  [theme.breakpoints.up('md')]: {
    width: '25rem',
  },
  [theme.breakpoints.up('lg')]: {
    width: '30rem',
  },
}));

const SubTitle = styled(Typography)(({ theme }) => ({
  color: '#404040',
  [theme.breakpoints.down('md')]: {
    fontWeight: 400,
    fontSize: '2rem',
  },
  [theme.breakpoints.up('md')]: {
    fontWeight: 400,
    fontSize: '2.5rem',
  },
  [theme.breakpoints.up('lg')]: {
    fontWeight: 400,
    fontSize: '3rem',
  },
}));

const HorizontalBar = styled('div')(({ theme }) => ({
  background: '#FD7B01',
  height: '0.3rem',
  boxShadow: 2,
  [theme.breakpoints.down('md')]: {
    width: '90%',
  },
  [theme.breakpoints.up('md')]: {
    width: '80%',
  },
  [theme.breakpoints.up('lg')]: {
    width: '60%',
  },
}));

const BodyText = styled('div')(({ theme }) => ({
  color: '#404040',
  textShadow: '0px 2px 2px rgba(0, 0, 0, 0.25)',
  fontWeight: 300,
  align: 'center',
  [theme.breakpoints.down('md')]: {
    width: '90%',
    fontSize: '1.5rem',
  },
  [theme.breakpoints.up('md')]: {
    width: '80%',
    fontSize: '1.8rem',
  },
  [theme.breakpoints.up('lg')]: {
    width: '60%',
    fontSize: '2.1rem',
  },
}));

function App() {
  return (
    <Box container pt={8}>
      <Box
        py={4}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Logo src={logo} />
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <SubTitle
          variant="h3"
          align="center"
          sx={{
            color: '#404040',
            textShadow: '0px 2px 2px rgba(0, 0, 0, 0.25)',
          }}
        >
          CHEGANDO EM BREVE
        </SubTitle>
      </Box>
      <Box py={3} display="flex" alignItems="center" justifyContent="center">
        <HorizontalBar />
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <BodyText align="center">
          Estamos trabalhando para oferecer a você a melhor experiencia em
          gestão agrícola!
        </BodyText>
      </Box>
    </Box>
  );
}

export default App;
